.modal-parent {
    height: 100vh;
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.637);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 20px;

    .pop-box {

        height: auto !important;
        // width: 100%;
        background-color: #ffffffd3;
        padding: 22px;
        border-radius: 5px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.225);
        // margin-bottom: 30px;
        position: relative;

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            height: 26px;
            width: 26px;
            background: var(--accent);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            cursor: pointer;

        }

        .contact-right {
            width: 100%;
            height: 100%;
            padding: 10px;
            gap: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;

          

            .pop-img-box {
                border-radius: 5px;
                height: 150px;
                // width: 100%;
                width: 110%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              
                .pop-overlay {
                    background: #00000071;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    z-index: 4;
                }

                .pop-img {
                    width: 100%;
                    height: 100%;
                    background: url('../Assets/popbg.webp');
                    position: relative;
                }
                .contact-headding {
                    font-size: 22px;
                    font-weight: 800;
                    position: absolute;
                    z-index: 55;
                    color: white;
                    text-align: center;
                    padding: 5px;
                }

            }

            .input-div {
                display: flex;
                gap: 20px;
                padding-bottom: 20px;

                input,
                select {
                    // background: var(--bg-color);
                    // background:#ffffffc8;
                    background: transparent;
                    width: 100%;
                    padding: 10px 10px;
                    border: none;
                    border-bottom: 1px solid gray;

                    &:focus {
                        outline: none;
                    }
                }

                input:focus {
                    outline: 0px 0px 1px 0px solid yellow !important;
                }

                input:focus {
                    border-bottom: 1px solid var(--accent) !important;
                }

                textarea {
                    border: none;
                    border-bottom: 1px solid gray;
                    padding: 15px 10px;
                    width: 100%;
                    background: transparent;

                    input:focus {
                        outline: 1px solid yellow !important;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .btn-div {
                display: flex;
            }
        }

    }
}

@media only screen and (max-width:500px) {
    .modal-parent {
        padding: 100px 10px;
        z-index: 1000;

        .pop-box {
            .close {
                top: 5px;
                right: 5px
            }
        }
    }
}