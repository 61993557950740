.header-parent {
    height: 60px;
    width: 100%;
    background: transparent;
    position: fixed;
    z-index: 100;
    box-shadow: 0px 0px 10px #00000064;
    background: #ffffff55;
    backdrop-filter: blur(5px);


    .header-container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;



        .logo-box {
            height: 50px !important;
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;

            .logo-image {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: url('../Assets/logo.webp');
            }

        }

        .contact-btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}