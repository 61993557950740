.contact-parent {
  position: relative;
  height: 600px;
  .contact-bg {
    height: 100%;
    width: 100%;
    background: url("../Assets/bg.png");
    opacity: 0.08;
  }
  .contact-cont {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 20px;

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 40px;
      .title {
        display: flex;
        justify-content: flex-start;
        color: var(--orange);
        font-size: 35px;
        font-weight: 900;
      }
      .form-data {
        display: flex;
        gap: 10px;

        flex-direction: column;

        .input-data {
          padding: 12px 20px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;

          &:focus {
            outline: 2px solid var(--accent);
          }
        }
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .right {
      width: 50%;
    .right-bg{
        background:url("../Assets//contact.png");
        background-size:cover;
        background-position: center center;
        background-repeat:no-repeat;
        height:500px;
        width:100%;
        border-radius: 10px;
    }

    }
  }
}
@media only screen and (max-width:1250px){
    .contact-parent{
        .contact-cont{
            padding:0px 40px;
        }
    }
}
@media only screen and (max-width:768px){
    .contact-parent{
        height:1000px;
        .contact-cont{
        flex-direction: column-reverse;
       
        .left{
            width:100%;
            padding-top: 30px;

            .title{
              font-size: 30px;
              text-align: center;
                justify-content: center;
            }
        }
        .right{
            width:100%;
            display:flex;
            align-items: center;
            justify-content: center;
            .right-bg{
                width:400px;
                height:400px;
            }
        }
        }
    }
}