.gallery-parent {
    padding: 30px 10px;
height: 100%;
background: #f7951d15;
position: relative;
    
.g-overlay{
    height: 100%;
    width: 100%;
    background: url('../Assets/bg.png');
    position: absolute;
    z-index: -1;
    opacity: 0.08;
}
    .gallery-container {
        padding: 40px 10px;
        position: relative;
        z-index:5;

        .mySwiper2 {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center !important;
            align-items: center;


            .swiper-button-next:after {
                display: none;
            }

            .swiper-button-prev:after {
                display: none;
            }

            margin :auto !important;

        }

        // .swiperslide{
        //     margin: auto;
        // }

        // .swiper-slides-box{
        //     // margin-left: auto;
        //     // margin-right: auto;
        //     // height: 100%;
        //     // width: 100%;

        .g-img-box {
            width: 280px;
            height: 320px;
            margin: auto;

            .g-img {
                height: 100%;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;
            }

            .g-img1 {
                background: url('../Assets/gallery/g1.webp');
            }
            .g-img2 {
                background: url('../Assets/gallery/g2.webp');
            }
            .g-img3 {
                background: url('../Assets/gallery/g3.webp');
            }
            .g-img4 {
                background: url('../Assets/gallery/g4.webp');
            }
            // .g-img1 {
            //     background: url('../Assets/mobile_img/m_a1.webp');
            // }
            .g-img5 {
                background: url('../Assets/gallery/g6.webp');
            }
            .g-img6 {
                background: url('../Assets/gallery/g7.webp');
            }
            .g-img7 {
                background: url('../Assets/gallery/g8.webp');
            }
            .g-img8 {
                background: url('../Assets/gallery/g9.webp');
            }
            .g-img9 {
                background: url('../Assets/gallery/g10.webp');
            }
           

        }


    }

}

// }