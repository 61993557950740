:root {
  --red: #bf1e2d;
  --purple: #4c166b;
  --orange1: #bf1e2d;
  --orange: #f7941d;
  --accent: #f7941d;
  --wl: 1200px;
  --black: #000;
  --white: #fff;
  --accent1: radial-gradient(
      60.66% 219.25% at 50% 49.55%,
      #bf1e2d 0%,
      #4c166b 100%
    ) ;

  --quick: 0.3s all ease-out;
  --medd: 0.6s all ease-out;
  --slow: 1s all ease-out;
  --bg-color: #fafafa;
  --footer-bg: #2f2e2e;
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap");

*,
*::after,
*::before {
  margin: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  // border: 1px solid rgb(154, 248, 154);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: center;
  word-spacing: 0.8;
  line-height: 120% !important;
}

p {
  // text-align: justify;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 1px;
  word-spacing: 1.5px;
  font-weight: 300;
  color: #000000;
}

.btn {
  padding: 10px 20px;
  background: var(--accent);
  color: var(--white);
  border: none;
  transition: var(--quick);
  border-radius: 5px;
  width: fit-content;
  text-decoration: none;
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    // background: var(--purple);
  }
}

.parent {
    width: 100%;

  .container {
    max-width: var(--wl);
    margin: auto;
  }
}

.bg-img-cover {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.section-heading {
  font-size: 30px;
  line-height: 30px;
  font-weight: 900;
  color: var(--orange)
}