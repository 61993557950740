.footer-parent {
  background: #0f182d;

  .footer-cont {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      color: var(--white);
      padding: 20px 0px;
    }

    .award {
      background: url("../Assets/fbg.png");
      width: 100%;
      height: 300px;
      opacity: 0.9;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .img-list {
        display: flex;
        align-items: center;

        .award-group {
          display: flex;
          align-items: center;

          img {
            width: 150px;
            height: auto;
            margin: 10px;
          }

        }

        .main-trophy {
          display: flex;
          align-items: center;

          img {
            width: 200px;
            height: 200px;
          }
        }
      }
    }
  }
}

.bottom-footer {
  width: 100%;
  background-image: url("../Assets/Footer\ image\ -\ small.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: auto;

  .bottom-footer-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    .email {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 25px;

      a {
        color: white;
        text-decoration: none;
        font-size: 16px;
      }
    }

    .copyright {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: white;
        .copyright-icon{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .icons {
      color: white;
      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        font-size: 25px;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .footer-parent {
    .footer-cont {
      .award {
        width: 100% !important;
        flex-wrap: wrap;

        .img-list {
          justify-content: center;

          flex-wrap: wrap;

          img {
            width: 100px !important;
          }

          .main-trophy {
            display: flex;
            align-items: center;

            img {
              width: 120px !important;
              height: 120px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:1024px) {
  .footer-parent {
    .footer-cont {
      .award {

        height: auto !important;

        .img-list {

          justify-content: center;
          width: 100%;
          flex-wrap: wrap;

          .main-trophy {
            width: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px 0px 0px;

            img {
              width: 120px !important;
              height: 120px !important;
            }
          }

          img {
            width: 120px !important;

          }

        }
      }

    }
  }
}

@media only screen and (max-width:800px) {
  .bottom-footer {

    .bottom-footer-cont {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      padding: 20px 20px;

    }
  }

}

@media only screen and (max-width:768px) {

  .footer-parent .footer-cont .award .img-list .main-trophy img {
    height: 90px !important;
    width: 90px !important;
  }
}