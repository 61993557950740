.hero-parent {
    // height: calc(100vh - 60px);
    height: 100svh;
    width: 100%;
    position: relative;

    .overlay {
        position: absolute;
        background: rgba(10, 2, 2, 0.65);
        background: linear-gradient(transparent 70%, black 100%);

        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .mySwiper {
        height: 100%;
        width: 100%;

        .swiper-button-next:after {
            font-size: 14px;
            color: white;
            border: 1px solid white;
            border-radius: 100%;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            margin-right: 150px;
        }

        .swiper-button-prev:after {
            font-size: 14px;
            color: white;
            border: 1px solid white;
            border-radius: 100%;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            margin-left: 150px;
        }
    }

    .swiper-slide {
        width: 100%;
        height: 100%;
        // position: relative;
    }

    .slide-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        gap: 10px;
        color: var(--white);
        z-index: 1000;
        position: absolute;
        text-align: center;
        padding: 0px 10px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 60px;


        .slide-headding {
            font-size: 2.8rem !important;
            font-weight: 900;
            font-weight: bolder;
            color: var(--white);
        }

        .slide-p {
            color: var(--white);
            font-size: 16px;
        }

    }

    .s-img1 {
        background: url('../Assets/swiper/img3.webp');
    }

    .s-img2 {
        background: url('../Assets/swiper/img1.webp');
    }

    .s-img3 {
        background: url('../Assets/swiper/img2.webp');
    }

    // }

}

@media only screen and (max-width:600px) {
    .hero-parent {

        .mySwiper .swiper-button-next:after {
            margin-right: 10px !important;
        }

        .swiper-button-prev:after {
            margin-left: 10px !important;
        }

        .slide-content .slide-headding {
            font-size: 30px !important;
        }

    }
}



@media only screen and (min-width:601px) and (max-width:700px) {
    .hero-parent {

        .slide-content .slide-headding {
            font-size: 36px !important;
        }

    }
}