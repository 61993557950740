.april-parent {
    position: relative;
    z-index: 7;

    .bg-overlay {
        background: url('../Assets/bg.png');
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
        opacity: 0.08;
    }

    .april-container {

        width: 100%;
        gap: 50px;
        padding: 50px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .heding-div {
            width: 100%;
            display: flex;

            justify-content: center;
            align-items: center;

            .section-heading {
                font-size: 30px;
                line-height: 30px;
                font-weight: 900;
                color: var(--orange)
            }
        }

        .two-box {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;


            .april-left {
                width: 50%;
                // height: 100%;
                display: flex;
                flex-direction: column;
                align-items: start;
                // justify-content: center;



                .april-left-text {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: start;
                    gap: 10px;

                    .sub-heading {
                        color: var(--orange);
                        font-size: 26px;
                        font-weight: 600;
                    }

                    // .para-p {
                    //     max-width: 40ch;
                    // }
                }
            }

            .april-right {
                width: 50%;
                height: 100%;
                display: flex;

                .april-right-img-box {
                    width: 100%;
                    height: 400px;

                    .april-right-img {
                        width: 100%;
                        height: 100%;
                        background: url('../Assets/ser-1.png');
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    /* -------------------------May section------------------- */
    .may-container {
        height: 100%;
        width: 100%;
        gap: 40px;
        padding: 100px 10px 50px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .heding-div {
            width: 100%;
            display: flex;

            justify-content: center;
            align-items: center;

            .section-heading {
                font-size: 30px;
                line-height: 30px;
                font-weight: 900;
                color: var(--orange)
            }
        }

        .two-box {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;

            .may-left {
                width: 50%;
                height: 100%;
                display: flex;

                .may-left-img-box {
                    width: 100%;
                    height: 400px;

                    .may-left-img {
                        width: 100%;
                        height: 100%;
                        background: url('../Assets/gallery/about-img.jpeg');
                        border-radius: 10px;
                    }
                }
            }

            .may-right {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: start;
                // background-color: red;
                gap: 20px;

                .right-top {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                }

                .right-bottom {
                    display: flex;
                    width: 100%;

                    align-items: start;
                    gap: 10px;



                    .rb1 {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        background: #f7951d15;
                        padding: 10px;
                        border-radius: 5px;

                        .t-date {
                            color: var(--accent);
                            font-weight: 800;
                            font-size: 20px;
                        }


                    }
                }


                .rb2 {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    background: #f7951d15;
                    padding: 10px;
                    border-radius: 5px;

                    .t-date {
                        color: var(--accent);
                        font-weight: 800;
                        font-size: 20px;
                    }
                }
            }
        }

        // .may-right-text {
        //     height: 100%;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: start;
        //     gap: 10px;

        //     .sub-heading {
        //         color: var(--orange);
        //         font-size: 26px;
        //         font-weight: 600;
        //         text-align: start;
        //     }

        // }
    }
}


@media only screen and (max-width:500px) {
    .april-parent {

        .april-container {

            .two-box {
                flex-direction: column-reverse;
                gap: 30px;
                align-items: center;

                .april-left {
                    width: 90%;
                    align-items: center;

                    // .section-heading {
                    //     font-size: 26px;
                    // }

                    .april-left-text {

                        .sub-heading {
                            font-size: 22px;
                        }

                        align-items: center;
                    }

                    .para-p {
                        text-align: center;
                    }
                }

                .april-right {
                    width: 90%;
                }
            }
        }
    }
}

@media only screen and (min-width:501px)and (max-width:599px) {
    .april-parent {
        .april-container {

            .two-box {
                flex-direction: column-reverse;
                gap: 30px;
                align-items: center;

                .april-left {
                    width: 80% !important;

                    align-items: center;

                    // .section-heading {
                    //     text-align: center;
                    // }

                    .april-left-text {
                        align-items: center;

                        .sub-heading {
                            text-align: center;
                        }

                        .para-p {
                            text-align: center;
                        }
                    }
                }

                .april-right {
                    width: 80%;
                }
            }
        }
    }
}

@media only screen and (min-width:600px)and (max-width:850px) {
    .april-parent {
        .april-container {

            .two-box {
                flex-direction: column-reverse;
                align-items: center;
                gap: 30px;

                .april-left {
                    width: 80%;
                    align-items: center;

                    // .section-heading {
                    //     text-align: center;
                    // }

                    .april-left-text {
                        align-items: center;

                        .sub-heading {
                            text-align: center;
                        }

                        .para-p {
                            text-align: center;
                        }
                    }
                }


                .april-right {
                    width: 80%;
                }
            }
        }
    }
}

// ------------------may------------

@media only screen and (max-width:500px) {
    .april-parent {

        .may-container {
            // flex-direction: column;
            // gap: 30px;
            padding-top: 50px;

            .two-box {
                width: 100%;
                flex-direction: column;
                gap: 30px;
                align-items: center;

                .may-right {
                    width: 90%;
                    align-items: center;

                    .right-top {
                        width: 100%;

                        .may-right-text {
                            align-items: center;

                            .sub-heading {
                                font-size: 22px;
                            }

                            align-items: center;
                        }

                        .para-p {
                            text-align: center;
                        }
                    }

                    .right-bottom {
                        align-items: center !important;

                        .rb1,
                        .rb2 {
                            align-items: center !important;

                            .t-date {
                                font-size: 15px;
                                text-align: center !important;
                            }

                            .exp-date {
                                font-size: 14px;
                                text-align: center !important;
                            }


                        }
                    }

                }

                .may-left {
                    width: 90%;
                }
            }
        }
    }
}

@media only screen and (min-width:501px)and (max-width:599px) {
    .april-parent {
        .may-container {
            flex-direction: column;
            gap: 30px;
            width: 100%;

            .two-box {
                width: 100%;
                flex-direction: column;
                gap: 30px;
                align-items: center;

                .may-left {
                    width: 80%;

                }

                .may-right {
                    width: 80%;
                    align-items: center;

                    .may-right-text {
                        align-items: center;

                        .sub-heading {
                            // font-size: 22px;
                        }

                        align-items: center;
                    }

                    .para-p {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:600px)and (max-width:850px) {
    .april-parent {
        .may-container {
            width: 100%;
            flex-direction: column;
            gap: 30px;

            .two-box {
                flex-direction: column;
                gap: 30px;
                align-items: center;

                .may-left {
                    width: 80%;
                    align-items: center;
                }

                .may-right {
                    width: 80%;
                    align-items: center;


                    .may-right-text {
                        align-items: center;

                        .sub-heading {
                            font-size: 22px;
                        }

                        align-items: center;
                    }

                    .para-p {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:801px)and (max-width:1200px) {
    .april-parent {

        .april-container {
            padding: 50px 20px;

            .para-p {
                max-width: 40ch;
            }
        }

        .may-container {
            padding: 50px 20px;

            .para-p {
                max-width: 40ch;
            }
        }


    }
}

@media only screen and (min-width:1200px) {
    .para-p {
        max-width: 40ch !important;
    }
}

@media only screen and (min-width:970px) {
    .section-heading {
        font-size: 35px !important;
    }
}