.bfooter-parent {
  padding: 100px 0px;
  background: url("../Assets/b-footer.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  .overlay {
    position: absolute;
    background: rgba(10, 2, 2, 0.562);
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .bfooter-cont {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;

    .main-content {
      color: white;
      font-size: 35px;
      font-weight: 800;
    }
    .sub-con {
      color: white;
    }
  }
}
@media only screen and (max-width: 600px) {
  .bfooter-parent {
    .bfooter-cont {
      .main-content {
        padding: 0px 20px;
        font-size: 25px;
      }
      .sub-con {
        text-align: center;
        padding: 0px 20px;
      }
    }
  }
}
