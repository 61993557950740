.accordian {
    width: 100%;
    height: auto;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;



    .heading {
        height: 40px;
        width: 100%;
        padding: 10px;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.32);
        justify-content: space-between;
           

        .acc-icon {
            font-size: 30px;
            color: var(--accent);
        }
    }

    .content {
        height: auto;
        width: 100%;
        padding: 5px;
        
    }
}